<template>
    <div class="en_add">
        <el-card style="margin-bottom: 10px;">
            <el-page-header :content="title" style="margin-bottom: 30px;" @back="goBack" />
            <nav class="" style="wdith:auto;margin-bottom: 10px;">
                <el-button type="primary" size="small" @click="collect">采集工商信息</el-button>
            </nav>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rule" label-width="auto" :inline="false" size="normal">
                <el-form-item label="统一社会信用代码">
                    <el-col :span="8">
                        <el-input v-model="form.creditCode" />
                    </el-col>
                </el-form-item>
                <el-form-item label="企业名称">
                    <el-col :span="8">
                        <el-input v-model="form.enterpriseName" />
                    </el-col>
                </el-form-item>
                <el-form-item label="经营状况">
                    <el-col :span="8">
                        <el-input v-model="form.regStatus" />
                    </el-col>
                </el-form-item>
                <!-- <el-form-item label="组织机构代码">
          <el-col :span="8">
            <el-input v-model="form.organizationCode" />
          </el-col>
        </el-form-item>
        <el-form-item label="纳税人识别号">
          <el-col :span="8">
            <el-input v-model="form.taxpayerId" />
          </el-col>
        </el-form-item> -->
                <el-form-item label="法定代表人">
                    <el-col :span="8">
                        <el-input v-model="form.authorizer" />
                    </el-col>
                </el-form-item>
                <el-form-item label="注册资本">
                    <el-col :span="8">
                        <el-input v-model="form.registerCapital" />
                    </el-col>
                </el-form-item>
                <el-form-item label="企业类型">
                    <el-col :span="8">
                        <el-input v-model="form.type" />
                    </el-col>
                </el-form-item>
                <el-form-item label="所属行业">
                    <el-col :span="8">
                        <el-input v-model="form.business" />
                    </el-col>
                </el-form-item>
                <el-form-item label="成立日期">
                    <el-col :span="8">
                        <el-input v-model="form.registerTime" />
                    </el-col>
                </el-form-item>
                <el-form-item label="注册地址">
                    <el-col :span="8">
                        <el-input v-model="form.address" />
                    </el-col>
                </el-form-item>
                <el-form-item label="经营范围">
                    <el-col :span="8">
                        <el-input type="textarea" :rows="4" v-model="form.businessScope" />
                    </el-col>
                </el-form-item>
                <template>
                    <el-form-item>
                        <el-button type="primary" size="medium" @click="onSubmit">{{ id ? "保存" : "提交" }}</el-button>
                    </el-form-item>
                </template>
            </el-form>
        </el-card>
        <Drawers ref="drawer" @sub="submit" :title="'采集工商信息'"></Drawers>
    </div>
</template>

<script>
import imgup from "../../../components/upImg/index.vue";
import rules from "../../../utils/rules";
import imgups from "../../../components/uplist/index.vue";
export default {
    components: {
        imgup,
        imgups,
    },
    data() {
        return {
            id: null,
            title: "加载中...",
            form: {
                name: "",
                fullName: "",
                logo: "",
                website: "",
                province: "",
                city: "",
                address: "",
                people: "",
                financing: "",
                type: [],
                business: [],
                subBusiness: [],
                serverArea: [],
                companyDesc: "",
                productDesc: "",
                productImages: [],
                serviceClose: 1,
                serviceFile: "",
            },

            rule: {
                name: [rules.req("请输入企业简称")],
                fullName: [rules.req("请输入企业全称")],
                logo: [rules.req("请输入企业logo")],
                city: [rules.req("请输入省份和城市")],
                people: [rules.req("请输入公司人事")],
                financing: [rules.req("请输入融资情况")],
                type: [rules.req("请输入类型")],
                business: [rules.req("请输入行业")],
                serverArea: [rules.req("请输入业务区域")],
                companyDesc: [rules.req("请输入企业介绍")],
                intro: [rules.req("请输入企业简介")],
            },
            action: {
                action: "enterprise",
            },
            province: {},
            city: {},
            typeList: [],
            businessList: [],
            subBusinessList: [],
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
        };
    },
    watch: {},
    computed: {},
    created() {
        this.getform();
    },
    methods: {
        goBack() {
            history.go(-1);
        },
        collect() {
            //   console.log(12)
            this.$refs.drawer.drawer = true;
            this.$refs.drawer.interfaces = 7;
            this.$refs.drawer.getlist();
        },
        submit(row) {
            this.form = { ...row, regStatus: row.result.regStatus };
        },
        async onSubmit() {
            if (this.id) {
                var { data: res } = await this.$http.post("/admin/Enterprise/editBusinessInfo", {
                    ...this.form,
                    enterpriseId: this.id,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    history.back();
                }
            } else {
                var { data: res } = await this.$http.post("/admin/Finance/setProject", {
                    id: this.$route.query.projectId,
                    businessInfo: JSON.stringify(this.form),
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    history.back();
                }
            }
        },

        async getform() {
            if (this.$route.query.id) {
                var id = this.$route.query.id;
                this.id = id;
                this.$http.get("/admin/Enterprise/getBusinessInfo?enterpriseId=" + id).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        //   console.log(res)
                        this.form = res.data.businessInfo ? res.data.businessInfo : {};
                        this.form.id = this.id * 1;
                        this.title = res.data.enterprise.fullName;
                        //  this.form.initViews =
                        //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
                    }
                });
            } else if (this.$route.query.projectId) {
                let { data: res } = await this.$http.get("/admin/Finance/getProjectDetail", {
                    params: { id: this.$route.query.projectId },
                });
                this.form = res.data.businessInfo || {};
                this.title = res.data.name;
            }
        },
    },
};
</script>

<style lang="less">
.en_add {
    .box {
        display: inline-block;
        width: 300px;
        input {
            border-color: #dcdfe6 !important;
        }
    }
}
</style>
